import './Skills.css';
import '../App.css';

import React from "react";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PaperScrollContent from "../PaperScrollContent";

export default class Skills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            skillsGroup: [
                {
                    title: "Industry Knowledge & Specialties",
                    skills:[
                        {
                            name:"Game Development",
                            score:4
                        },
                        {
                            name:"Game Design",
                            score:4
                        },
                        {
                            name:"Computer Programming",
                            score:4
                        },
                        {
                            name:"Game Experience",
                            score:2.5
                        },
                        {
                            name:"Machine Learning",
                            score:1
                        },
                    ]
                },
                {
                    title: "Tools & Technologies",
                    skills:[
                        {
                            name:"Java",
                            score:4,
                            testURL:"https://www.linkedin.com/in/kiansatvati/detail/assessments/Java/report/"
                        },
                        {
                            name:"C/C++",
                            score:4,
                            testURL:"https://www.linkedin.com/in/kiansatvati/detail/assessments/C++/report/"
                        },
                        {
                            name:"C#",
                            score:4,
                            testURL:"https://www.linkedin.com/in/kiansatvati/detail/assessments/C%23/report/"
                        },
                        {
                            name:"Unity",
                            score:3.5,
                            testURL: "https://www.linkedin.com/in/kiansatvati/detail/assessments/Unity/report/"
                        },
                        {
                            name:"Adobe Photoshop",
                            score:3,
                            testURL: "https://www.linkedin.com/in/kiansatvati/detail/assessments/Adobe%20Photoshop/report/"
                        },
                        {
                            name:"Rhinoceros (Rhino 3D)",
                            score:3
                        },
                        {
                            name:"Blender",
                            score:2.5
                        },
                        {
                            name:"ReactJS",
                            score:1
                        },
                    ]
                },
                {
                    title: "Languages",
                    skills:[
                        {
                            name:"Persian",
                            score:5
                        },
                        {
                            name:"English",
                            score:4.5
                        },
                        {
                            name:"French",
                            score:0.5
                        },
                    ]
                },
                {
                    title: "Interpersonal Skills",
                    skills:[
                        {
                            name:"Highly adaptable in diversified situations",
                            score:5
                        },
                        {
                            name:"Committed as a hard-working team member",
                            score:5
                        },
                        {
                            name:"Enthusiast for continual learning",
                            score:5
                        },
                    ]
                },
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    valueGenerator() {
        const skillGroup = this.state.skillsGroup[this.state.currPage - 1];
        return (
            <Skill title={skillGroup.title} skills={skillGroup.skills} />
        );
    }

    goToNextPage() {
        if (this.state.currPage < this.state.skillsGroup.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.skillsGroup.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollRight"} id={"Skills"}>
                <div className={"TrainingCampContainer"} >
                    <div className={"TrainingCamp"} id={"TrainingCamp"}
                         onMouseEnter={() => this.toggleTextVisibility()}
                         onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Skills
                            </span>
                        }
                    </div>
                    <div className={"TrainingCampShadow"} id={"TrainingCampShadow"} />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"PaperScrollRight"}>
                    <PaperScrollContent position={"Right"} value={this.valueGenerator()} nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.skillsGroup.length}
                    />
                </div>
            </div>
        );
    }
}

class Skill extends React.Component {

    GenerateSkillName(skill) {
        if (skill?.testURL){
            return (
                <div className={"SkillName"}>
                    <a className={"SkillNameURL tooltip"} href={skill.testURL}>
                        <span className="tooltiptext">Linkedin Assessment Quiz</span>
                        {skill.name}
                    </a>
                </div>
            )
        }
        else {
            return (
                <div className={"SkillName"}>
                    {skill.name}
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <h1 className={"SkillsTitle"}>
                    {this.props.title}
                </h1>
                <hr className={"SkillsHR"} />
                <div className={"SkillGroupsContainer"}>
                    {this.props.skills.map(skill => (
                        <div className={"SkillContainer"} key={skill.name}>
                            {this.GenerateSkillName(skill)}
                            <Rating
                                name={skill.name}
                                value={skill.score}
                                precision={0.5}
                                size={"small"}
                                readOnly={true}
                            />
                        </div>
                    ))}
                </div>

            </div>
        );
    }
}