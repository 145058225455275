import './About.css';

import React from "react";
import NavButton from "./NavButton";

export default class NavPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"NavPanel"}>
                <NavButton ButtonName={"About"} DestId={"About"} />
                <NavButton ButtonName={"Educations"} DestId={"Educations"} />
                <NavButton ButtonName={"Work Experiences"} DestId={"WorkExperiences"} />
                <NavButton ButtonName={"Certifications & Honors"} DestId={"Certifications_Honors"} />
                <NavButton ButtonName={"Skills"} DestId={"Skills"} />
                <NavButton ButtonName={"Projects"} DestId={"Projects"} />
                <NavButton ButtonName={"Publications"} DestId={"Publications"} />
                <NavButton ButtonName={"CV / Contact Me"} DestId={"ContactMe"} />
            </div>
        );
    }
}