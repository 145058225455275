import './ContactMe.css';
import '../App.css';

import React from "react";
import ContactMePanelContainer from "./ContactMePanel";

export default class ContactMe extends React.Component {

    constructor() {
        super();
        this.state = {
            showContactMePanel: false
        }
    }

    hideContactMePanel() {
        this.setState({showContactMePanel: false});
    }

    showContactMePanel() {
        this.setState({showContactMePanel: true});
    }

    render() {
        const copy = '\u00a9';
        return (
            <div className={"ContactMeMainContainer"} id={"ContactMe"}>
                {this.state.showContactMePanel && <ContactMePanelContainer closeMenu={() => this.hideContactMePanel()} />}
                <div className={"PortalContainer"}>
                    <div className={"Portal"} id={"Portal"} onClick={() => this.showContactMePanel()}>
                        <div className={"PortalText"}>
                            CV / Contact me!
                        </div>
                    </div>
                    {/*<div className={"PortalShadow"} id={"PortalShadow"}/>*/}
                </div>
                <div className={"CopyrightContainer"}>
                    <div className={"CopyrightText"}>
                        Copyright {copy} 2021 Kian RAZAVI SATVATI, All rights reserved.
                    </div>
                </div>
            </div>
        );
    }
}