import './About.css';

import React from "react";
import {Link} from "react-scroll";

export default class NavButton extends React.Component {

    constructor(props) {
        super(props);
    }

    convertVHtoPx(vhValue) {
        const oneVhInPx = window.innerHeight / 100;
        if (window.innerHeight < 1080) {
            return (1080 / 100) * vhValue;
        }
        else {
            return oneVhInPx * vhValue;
        }
    }

    render() {
        if (this.props.DestId === "About"){
            return (
                <Link activeClass={"active"} to={this.props.DestId} spy={true} smooth={true} duration={1000}
                      offset={250} className={"NavButton AboutToolTip"}>
                    {this.props.ButtonName}
                    <span className="AboutToolTipText">You are already here!</span>
                </Link>
            );
        }
        else if (this.props.DestId === "ContactMe") {
            return (
                <Link activeClass={"active"} to={this.props.DestId} spy={true} smooth={true} duration={1000}
                      offset={200} className={"NavButton"}>
                    {this.props.ButtonName}
                </Link>
            );
        }
        else {
            return (
                <Link activeClass={"active"} to={this.props.DestId} spy={true} smooth={true} duration={1000}
                      className={"NavButton"}>
                    {this.props.ButtonName}
                </Link>
            );
        }
    }
}