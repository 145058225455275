import './ContactMe.css';
import '../App.css';
import emailIcon from './emailIcon.png';
import linkedinIcon from './linkedin.png';
import githubIcon from './github.png';
import gitlabIcon from './gitlab.png';
import researchGateIcon from './researchgate.png';
import twitterIcon from './twitter.png';

import React from "react";

export default class ContactMePanelContainer extends React.Component {

    render() {
        return (
            <div className={"ContactMePanelContainer"}>
                <div className={"ContactMePanel"}>
                    <div className={"ContactMePanelCloseButton"} onClick={this.props.closeMenu} />
                    <div className={"ContentsContainer"}>
                        <h1>Contact Me</h1>
                        {/*Email*/}
                        <p className={"Box"} id={"email"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${emailIcon})`}} />
                            <a href={"mailto:kiansatvati@gmail.com"} target={"_blank"} className={"Content"}>
                                kiansatvati@gmail.com
                            </a>
                        </p>
                        {/*LinkedIn*/}
                        <p className={"Box"} id={"linkedin"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${linkedinIcon})`}} />
                            <a href={"https://www.linkedin.com/in/kiansatvati/"} target={"_blank"} className={"Content"}>
                                Kian R. Satvati
                            </a>
                        </p>
                        {/*github*/}
                        <p className={"Box"} id={"github"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${githubIcon})`}} />
                            <a href={"https://github.com/XxNE0xX"} target={"_blank"} className={"Content"}>
                                XxNE0xX
                            </a>
                        </p>
                        {/*gitlab*/}
                        <p className={"Box"} id={"gitlab"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${gitlabIcon})`}} />
                            <a href={"https://gitlab.com/XxNE0xX"} target={"_blank"} className={"Content"}>
                                XxNE0xX
                            </a>
                        </p>
                        {/*researchGate*/}
                        <p className={"Box"} id={"researchGate"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${researchGateIcon})`}} />
                            <a href={"https://www.researchgate.net/profile/Kian-Razavi-Satvati"} target={"_blank"} className={"Content"}>
                                Kian Razavi Satvati
                            </a>
                        </p>
                        {/*twitter*/}
                        <p className={"Box"} id={"twitter"}>
                            <div className={"Icon"} style={{backgroundImage: `url(${twitterIcon})`}} />
                            <a href={"https://twitter.com/KianSatvati"} target={"_blank"} className={"Content"}>
                                Kian Satvati
                            </a>
                        </p>
                        {/*cv*/}
                        <a href={"https://s3.amazonaws.com/kiansatvati.info/Data/Kian_RAZAVI_SATVATI_CV.pdf"} target={"_blank"} className={"CV"} id={"cv"}>
                            Download CV
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}