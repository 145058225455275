import "./Characters.css";

import KianNormal from "./KianNormal.png";
import KianHappy from "./KianHappy.png";

import React from "react";
import {Link} from "react-scroll";

export default class Kian extends React.Component {

    constructor() {
        super();
        this.state={
            showText: false,
            currentImage: KianNormal
        }
    }

    changeToHappy() {
        this.setState({currentImage: KianHappy, showText: true});
    }

    changeToNormal() {
        this.setState({currentImage: KianNormal, showText: false});
    }

    render() {
        return (
            <Link title={"Contact Me!"} activeClass={"active"} to={"ContactMe"} spy={true} smooth={true} duration={1000}
                  className={"KianAvatarContainer"} offset={200}
                  onMouseEnter={() => this.changeToHappy()}
                  onMouseLeave={() => this.changeToNormal()}>
                {this.state.showText &&
                    <div className={"WannaChatText"}>
                        Wanna have a chat?
                    </div>
                }
                <img src={this.state.currentImage} className={"KianAvatar"}/>
            </Link>
        );
    }
}