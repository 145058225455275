import './About.css';
import SpeechBubbleImage from "./SpeechBubble.png";

import React from "react";
import Typist from "react-typist";
import NavPanel from "./NavPanel";

export default class About extends React.Component {

    constructor() {
        super();
        this.state = {
            firstRender: true,
        }
    }

    firstRenderDone() {
        this.setState({firstRender: false})
    }

    render() {
        return (
            <div className={"About"} id={"About"}>

                <div className={"SignpostStem"}>
                    <NavPanel />
                </div>
                <div className={"SignpostShadow"} />
                {this.props.isAboutShown &&
                <div className={"AboutSpeechBubble"} id={"AboutSpeechBubble"}>
                    <div className={"AboutText"}>
                        {!this.state.firstRender &&
                        <div>
                            <p className={"SalutationText"}>
                                Hello traveler!
                            </p>
                            <p>
                            I am Kian Razavi Satvati. Welcome to my personal website.
                            </p>
                            <p>
                            I am a self-trained game designer and game programmer.
                            </p>
                            <p>
                            I have got a really deep enthusiasm on video games.
                            As a matter of fact, I believe that games bring so much fun to both the game developers
                            and the people who find joy in playing them.
                            In other words, building games besides its funny nature, gives an outstanding
                            opportunity in which one can be participated
                            in a team consists of multi-skilled members with top class creative minds relevant to
                            all divisions of the video games
                            industry; such as art, design, program, management, and so on. Each member of these
                            teams is surrounded with so many
                            talented people in those fields.
                            </p>
                            <p className={"ResearchInterestsText"}>
                                Research Interests:
                            </p>
                            <p>
                                I would like to work on the implementation methodologies of Machine Learning;
                                a tool in order to help game developers,
                                <b>especially Procedural Content Generation (PCG)</b> for <b>World-Building</b>&nbsp;
                                and <b>Environment Design</b> in video games.
                                Moreover, I am interested in the applications of Artificial Intelligence (AI)&nbsp;
                                to tackle the higher level problem of <b>Designing the Mechanics of A Game</b>.
                            </p>
                        </div>
                        }
                        {this.state.firstRender &&
                            <Typist avgTypingDelay={1} cursor={{hideWhenDone: true, hideWhenDoneDelay: 0}}
                                    onTypingDone={() => this.firstRenderDone()}>
                                <p className={"SalutationText"}>
                                    Hello traveler!
                                </p>
                                <Typist.Delay ms={300}/>
                                <p>
                                    I am Kian Razavi Satvati. Welcome to my personal website.
                                </p>
                                <Typist.Delay ms={100}/>
                                <p>
                                    I am a self-trained game designer and game programmer.
                                </p>
                                <p>
                                    I have got a really deep enthusiasm on video games.
                                    As a matter of fact, I believe that games bring so much fun to both the game
                                    developers
                                    and the people who find joy in playing them.
                                    In other words, building games besides its funny nature, gives an outstanding
                                    opportunity in which one can be participated
                                    in a team consists of multi-skilled members with top class creative minds relevant
                                    to
                                    all divisions of the video games
                                    industry; such as art, design, program, management, and so on. Each member of these
                                    teams is surrounded with so many
                                    talented people in those fields.
                                </p>
                                <Typist.Delay ms={50}/>
                                <p className={"ResearchInterestsText"}>
                                    Research Interests:
                                </p>
                                <p>
                                    I would like to work on the implementation methodologies of Machine Learning;
                                    a tool in order to help game developers,
                                    <b>especially Procedural Content Generation (PCG)</b> for <b>World-Building</b>&nbsp;
                                    and <b>Environment Design</b> in video games.
                                    Moreover, I am interested in the applications of Artificial Intelligence (AI)&nbsp;
                                    to tackle the higher level problem of <b>Designing the Mechanics of A Game</b>.
                                </p>
                            </Typist>
                        }
                    </div>
                </div>
                }
            </div>
        );
    }
}