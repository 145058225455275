import './App.css';

import React from "react";

export default class PaperScrollContent extends React.Component {

    render() {
        return (
            <div className={"PaperScrollContent" + this.props.position}>
                <div className={"ActualValues"}>
                    {this.props.value}
                </div>
                <div className={"ButtonsPanel"}>
                    {this.props.prevAvailable &&
                    <div className={"ButtonPrev"} onClick={this.props.prevPage}/>
                    }
                    <div className={"CurrentPageScrollDisplay"}>
                        {this.props.currPage}/{this.props.totalPages}
                    </div>
                    {this.props.nextAvailable &&
                    <div className={"ButtonNext"} onClick={this.props.nextPage}/>
                    }
                </div>
            </div>
        );
    }
}