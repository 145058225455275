import "./App.css";

import React from "react";
import About from "./About/About";
import Educations from "./Education/Educations";
import WorkExperiences from "./WorkExperience/WorkExperiences";
import Certification_Honors from "./Certification_Honors/Certification_Honors";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Publications from "./Publications/Publications";
import ContactMe from "./ContactMe/ContactMe";

export default class Background extends React.Component {

    render() {
        return (
            <div className={"BG"} id={"BG"}>
                <div className={"MainPanelScrollLeft"} id={"top"} />
                <About isAboutShown={this.props.isAboutShown} paragraphRenderCount={this.props.aboutParagraphRenderCount}/>
                <Educations />
                <WorkExperiences />
                <Certification_Honors />
                <Skills />
                <Projects />
                <Publications />
                <ContactMe />
            </div>
        );
    }
}