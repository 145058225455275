import './Certification_Honors.css';
import '../App.css';

import React from "react";
import PaperScrollContent from "../PaperScrollContent";

export default class Certification_Honors extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            CHs: [
                {
                    title: "GRE General",
                    issuer: "ETS",
                    date: "Oct. 2021",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1635472376880)/",
                    theDescriptions: ["Verbal: 157/170",
                                      "Quant: 170/170",
                                      "Analytical: 4.0/6.0"]
                },
                {
                    title: "TOEFL iBT",
                    issuer: "ETS",
                    date: "Sep. 2021",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1635468962514)/",
                    theDescriptions: ["Overall score: 114 / 120"]
                },
                {
                    title: "Certificate of Honor: Best Game AI Jam Project by Popular Vote at \"3rd International Summer School on Artificial Intelligence and Games\"",
                    issuer: "Dr. Julian Togelius and Dr. Georgios N. Yannakakis",
                    date: "Jul. 2021",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1635463488442)/",
                    theDescriptions: ["As a group of six people, we succeeded at presenting the best project based on popular vote, among 11 projects."]
                },
                {
                    title: "Certificate of Participation: \"3rd International Summer School on Artificial Intelligence and Games\"",
                    issuer: "modl.ai",
                    date: "Jul. 2021",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1635463485857)/",
                    theDescriptions: ["A 5-day long summer school, with the focus on application of Artificial Intelligence on the video games. Main topics: AI that plays, AI that designs, AI that experiences"]
                },
                {
                    title: "Certificate of Completion: Machine Learning Engineer Certificate",
                    issuer: "Rahnema College",
                    date: "Jul. 2021",
                    credentialURL: "https://rahnemacollege.com/certificate/kiancoder1yC61gC0Z",
                    theDescriptions: ["A 9 weeks long internship program by Rahnema College, July 2021"]
                },
                {
                    title: "Certificate of Completion: Improving Deep Neural Networks: Hyperparameter Tuning, Regularization and Optimization",
                    issuer: "Coursera",
                    date: "May 2021",
                    credentialURL: "https://www.coursera.org/account/accomplishments/certificate/AG59FFLWZX4R",
                    theDescriptions: []
                },
                {
                    title: "Certificate of Paper Acceptance: \"The 6th International Conference on Computer Games; Challenges and Opportunities\" (2021)",
                    issuer: "CGCO 2021",
                    date: "Feb. 2021",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1615375738407)/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_featured%3BMUneEs%2F1TnmHYDzywe2WLA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_self_edit_featured-featured_item_detail_view",
                    theDescriptions: []
                },
                {
                    title: "Certificate of Completion: Neural Network and Deep Learning",
                    issuer: "Coursera",
                    date: "Sep. 2020",
                    credentialURL: "https://www.coursera.org/account/accomplishments/certificate/56QHUXPF9UEC",
                    theDescriptions: []
                },
                {
                    title: "EF English Standard Test \n(EF SET)",
                    issuer: "EF SET",
                    date: "Aug. 2020",
                    credentialURL: "https://www.efset.org/cert/PaoEyG",
                    theDescriptions: ["86 / 100, C2 Proficient"]
                },
                {
                    title: "Certificate of Completion: Game Mechanics and System Design Course",
                    issuer: "Iran Game Development Institute",
                    date: "Oct. 2019",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1602876341181)/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_featured%3BG4G3azS9QTyU6MV3KQ%2BgDw%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_view_base_featured-featured_item_detail_view",
                    theDescriptions: []
                },
                {
                    title: "Certificate of Completion: UI/UX Design Course",
                    issuer: "Shahid Beheshti University",
                    date: "May 2019",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1615375695262)/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_featured%3BMUneEs%2F1TnmHYDzywe2WLA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_self_edit_featured-featured_item_detail_view",
                    theDescriptions: []
                },
                {
                    title: "Certificate of Attendance: \"Retalk: First Child-Centric Application Design Start-Up\" Workshop",
                    issuer: "Shahid Beheshti University",
                    date: "Oct. 2018",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1615375631889)/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_featured%3BMUneEs%2F1TnmHYDzywe2WLA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_self_edit_featured-featured_item_detail_view",
                    theDescriptions: []
                },
                {
                    title: "Certificate of Honor: \"Science and Technology Day Commemoration Ceremony at Faculty of Computer Engineering\" Programming Contest",
                    issuer: "Dr. Mehrnoosh Shamsfard, Dean of Computer Engineering Faculty at Shahid Beheshti University",
                    date: "Dec. 2017",
                    credentialURL: "https://www.linkedin.com/in/kiansatvati/detail/overlay-view/urn:li:fsd_profileTreasuryMedia:(ACoAAB2_CzkBybuf1ecZQtxIaIG9r3RAbfMf-Zs,1615375431593)/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_self_edit_featured%3BMUneEs%2F1TnmHYDzywe2WLA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_profile_self_edit_featured-featured_item_detail_view",
                    theDescriptions: ["Third rank among first year students"]
                },
                {
                    title: "Top 1 Percent in Iranian Universities' Nationwide Entrance Exam",
                    issuer: "National Organization for Educational Testing (Sanjesh)",
                    date: "Sep. 2017",
                    credentialURL: "",
                    theDescriptions: ["Among 150,000 contestants"]
                }
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    goToNextPage() {
        if (this.state.currPage < this.state.CHs.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }

    valueGenerator() {
        const ch = this.state.CHs[this.state.currPage - 1];
        return (
            <Certification_Honor title={ch.title} issuer={ch.issuer} date={ch.date} credentialURL={ch.credentialURL} theDescriptions={ch.theDescriptions} />
        );
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.CHs.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollLeft"} id={"Certifications_Honors"} >
                <div className={"PaperScrollLeft"}>
                    <PaperScrollContent position={"Left"} value={this.valueGenerator()}
                                        nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.CHs.length} />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"MuseumContainer"} >
                    <div className={"Museum"} id={"Museum"}
                    onMouseEnter={() => this.toggleTextVisibility()}
                    onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Certifications, Licences,
                                <br/>
                                and Honors
                            </span>
                        }
                    </div>
                    <div className={"MuseumShadow"} id={"MuseumShadow"} />
                </div>
            </div>
        );
    }
}

class Certification_Honor extends React.Component {
    render() {
        let URL = this.props.credentialURL;
        return (
            <div>
                <h1 className={"CHTitle"}>
                    {this.props.title}
                </h1>
                <h2 className={"CHIssuer"}>
                    Issuer: {this.props.issuer}
                </h2>
                <div className={"CHBottomSideContainer"}>
                    {(URL !== "") && <a className={"CHURL"} href={URL} target={"_blank"}>See Credential</a>}
                    <div className={"CHDate"}>
                        {this.props.date}
                    </div>
                </div>
                <hr className={"CHHR"} />
                <ul className={"CHDescriptionList"}>
                    {this.props.theDescriptions.map(theDescription => (
                        <li className={"CHDescriptionListItem"}>
                            {theDescription}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}