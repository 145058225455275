import "./App.css";

import BoatImage from "./Boat.gif";

import React from "react";

export default class Boat extends React.Component {

    render() {
        return (
            <img src={BoatImage} className={"Boat"} />
        );
    }
}