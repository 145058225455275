import './Publications.css';
import '../App.css';

import React from "react";
import PaperScrollContent from "../PaperScrollContent";

export default class Publications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            publications: [
                {
                    title: "Looking at Intergenerational Game Design through the Lens of Game Genres",
                    date: "February 17, 2021",
                    publisher: "\"Computer Games; Challenges and Opportunities\" Conference at Isfahan, Iran",
                    publicationUrl: "https://www.researchgate.net/publication/347985799_Looking_at_Intergenerational_Game_Design_through_the_Lens_of_Game_Genres",
                    coAuthors: "Dr. Mojtaba Vahidi-Asl",
                    theDescription: "We live in an aging society, and in the future, we will face an increase in the need for meaningful interactions between young children and seniors. Intergenerational games as a means for providing such interactions will play a major role in upcoming decades, thus having a framework for designers to make such games is crucial."
                },
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    goToNextPage() {
        if (this.state.currPage < this.state.publications.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }

    valueGenerator() {
        const publication = this.state.publications[this.state.currPage - 1];
        return (
            <Publication title={publication.title} date={publication.date} publisher={publication.publisher}
                         publicationUrl={publication.publicationUrl} coAuthors={publication.coAuthors}
                         theDescription={publication.theDescription} />
        );
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.publications.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollRight"} id={"Publications"}>
                <div className={"BookContainer"} >
                    <div className={"Book"} id={"Book"}
                         onMouseEnter={() => this.toggleTextVisibility()}
                         onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Publications
                            </span>
                        }
                    </div>
                    <div className={"BookShadow"} id={"BookShadow"} />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"PaperScrollRight"}>
                    <PaperScrollContent position={"Right"} value={this.valueGenerator()} nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.publications.length}
                    />
                </div>
            </div>
        );
    }
}

class Publication extends React.Component {
    render() {
        let URL = this.props.publicationUrl;
        return (
            <div>
                <h1 className={"PublicationTitle"}>
                    {this.props.title}
                </h1>
                <h2 className={"PublicationDate"}>
                    {this.props.date}
                </h2>
                <p className={"PublicationPublisher"}>
                    Publisher: {this.props.publisher}
                </p>
                <p className={"PublicationCoAuthors"}>
                    Co-Authors: {this.props.coAuthors}
                </p>
                <div className={"PublicationURLContainer"}>
                    {(URL !== "") && <a className={"PublicationURL"} href={URL} target={"_blank"}>See publication</a>}
                </div>
                <hr className={"PublicationHR"} />
                <div className={"PublicationDescription"}>
                    {this.props.theDescription}
                </div>
            </div>
        );
    }
}