import './Educations.css';
import '../App.css';

import React, {useState} from "react";
import {Element} from "react-scroll";
import PaperScrollContent from "../PaperScrollContent";

export default class Educations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            educations: [
                {
                    school: "University of Alberta",
                    degree: "Master's of Science, Computing Science",
                    years: "Sep. 2022 - Sep. 2024",
                    theDescriptions: []
                },
                {
                    school: "Shahid Beheshti University",
                    degree: "Bachelor's of Science, Computer Engineering",
                    years: "Sep. 2017 - Feb. 2022",
                    theDescriptions: ["Cumulative grade: 16.43 / 20"]
                },
                {
                    school: "National Organization for Development of Exceptional Talents (Sampad)",
                    degree: "Preparatory School Diploma, Mathematics and Physics",
                    years: "Sep. 2016 - Jun. 2017",
                    theDescriptions: ["Cumulative grade: 19.40 / 20"]
                },
                {
                    school: "National Organization for Development of Exceptional Talents (Sampad)",
                    degree: "High School Diploma, Mathematics and Physics",
                    years: "Sep. 2013 - Jun. 2016",
                    theDescriptions: ["Cumulative grade: 19.39 / 20"]
                }
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    goToNextPage() {
        if (this.state.currPage < this.state.educations.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }


    valueGenerator() {
        const education = this.state.educations[this.state.currPage - 1];
        return (
            <Education school={education.school} degree={education.degree} years={education.years} theDescriptions={education.theDescriptions} />
        );
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.educations.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollLeft"} id={"Educations"}>
                <div className={"PaperScrollLeft"}>
                    <PaperScrollContent position={"Left"} value={this.valueGenerator()} nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.educations.length}
                    />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"SchoolContainer"} >
                    <div className={"School"} id={"School"}
                         onMouseEnter={() => this.toggleTextVisibility()}
                         onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Educations
                            </span>
                        }
                    </div>
                    <div className={"SchoolShadow"} id={"SchoolShadow"} />
                </div>
            </div>
        );
    }
}

class Education extends React.Component {
    render() {
        return (
            <div>
                <h1 className={"EducationHeader"}>
                    {this.props.school}
                </h1>
                <hr className={"EducationHR"} />
                <h2 className={"EducationDegree"}>
                    {this.props.degree}
                </h2>
                <p className={"EducationYears"}>
                    {this.props.years}
                </p>
                <ul className={"EducationDescriptionList"}>
                    {this.props.theDescriptions.map(theDescription => (
                        <li className={"EducationDescriptionListItem"}>
                            {theDescription}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}