import "./Characters.css";

import VisitorImage from "./Visitor.png";

import React from "react";

export default class Boat extends React.Component {

    render() {
        return (
            <img src={VisitorImage} className={"VisitorAvatar"} />
        );
    }
}