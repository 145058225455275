import './App.css';
import Background from "./Background";

import React, {useState} from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import {Link} from "react-scroll";
import Boat from "./Boat";
import Kian from "./Characters/Kian";
import Visitor from "./Characters/Visitor";

function App() {
    const convertVHtoPx = (vhValue) => {
        const oneVhInPx = window.innerHeight / 100;
        if (window.innerHeight < 1080) {
            return (1080 / 100) * vhValue;
        }
        else {
            return oneVhInPx * vhValue;
        }
    }
    const convertVWtoPx = (vwValue) => {
        const oneVwInPx = window.innerWidth / 100;
        return oneVwInPx * vwValue;
    }

    const {scrollYProgress} = useViewportScroll();
    const boatScaleAnim = useTransform(scrollYProgress, [0, 0.07], [0.1, 1]);
    const boatYPosAnim = useTransform(scrollYProgress,
         [0, 0.07],
        [convertVHtoPx(53), convertVHtoPx(70)]);
    const boatXPosAnim = useTransform(scrollYProgress,
         [0, 0.023, 0.046, 0.07],
        [convertVWtoPx(-10), convertVWtoPx(10), convertVWtoPx(-10), convertVWtoPx(0)]);

    const initialVisitorYPosAnim = useTransform(scrollYProgress,
        [0.07, 0.08, 0.1],
        [convertVHtoPx(70), convertVHtoPx(50), convertVHtoPx(100)]);

    const kianYPosAnim = useTransform(scrollYProgress,
        [0.1, 1],
        [convertVHtoPx(100), convertVHtoPx(840)]);

    const visitorYPosAnim = useTransform(scrollYProgress,
        [0.07, 0.08, 0.1, 1],
        [convertVHtoPx(100), convertVHtoPx(70), convertVHtoPx(100), convertVHtoPx(840)]);
    const visitorScaleJumpAnim = useTransform(scrollYProgress,
        [0, 0.07, 0.1],
        [0, 0, 1]);

    const [isAboutShown, setIsAboutShown] = useState(false);
    const [aboutParagraphRenderCount, setAboutParagraphRenderCount] = useState(0);
    const [smallSignpostShown, setSmallSignpostShown] = useState(false);

    scrollYProgress.onChange(x => {
        setIsAboutShown(x > 0.11);
        setSmallSignpostShown(x > 0.24);
    })

    return (
        <div className="App">
            {smallSignpostShown &&
                <Link activeClass={"active"} to={"About"} spy={true} smooth={true} duration={1000}
                  className={"SmallSignpost"} offset={250}>
                    <span className="SmallSignpostToolTipText">Back to the Signpost?</span>
                </Link>
            }
            <div className={"BoatContainer"}>
                <motion.div
                    style={{
                        scale: boatScaleAnim,
                        y: boatYPosAnim,
                        x: boatXPosAnim
                    }}
                >
                    <Boat />
                </motion.div>
            </div>
            <div className={"CharactersContainer"}>
                <div className={"ScrollSlowlyText"}>
                    <p>
                        Last Update: <br/>
                        <span className={"LastUpdateText"}>Sep. 2022</span>
                    </p>
                    <p className={"OptimizedText"}>
                        "Optimized for desktop!"
                    </p>
                    Scroll Slowly :)
                </div>
                <motion.div
                    style={{
                        y: kianYPosAnim
                    }}
                >
                    <Kian />
                </motion.div>
                <motion.div
                    style={{
                        y: visitorYPosAnim,
                        scale: visitorScaleJumpAnim
                    }}
                >
                    <Visitor />
                </motion.div>
            </div>
            <Background isAboutShown={isAboutShown} />
        </div>
    );
}

export default App;