import './WorkExperiences.css';
import '../App.css';

import React from "react";
import PaperScrollContent from "../PaperScrollContent";

export default class WorkExperiences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            workExperiences: [
                {
                    company: "Rahnema College",
                    title: "Machine Learning Engineer - Intern",
                    location: "Tehran, Iran",
                    years: "May. 2021 - Jul. 2021",
                    theDescriptions: [
                        "An intensive 9-week long introduction program for ML applications in the industry",
                        "The development of a Web Server Intrusion Detector as the main project"]
                },
                {
                    company: "Shahid Beheshti University",
                    title: "Teaching Assistant, Embedded Systems",
                    location: "Tehran, Iran",
                    years: "Sep. 2021 - Jan. 2022",
                    theDescriptions: [
                        "Instructed by Dr. Seyed-Hosein Attarzadeh-Niaki",
                        "Responsibilities included mentoring, and grading homework and projects"]
                },
                {
                    company: "Shahid Beheshti University",
                    title: "Teaching Assistant, Research Methodology",
                    location: "Tehran, Iran",
                    years: "Sep. 2020 - Jan. 2021",
                    theDescriptions: [
                        "Instructed by Dr. Mahmood Neshati",
                        "Responsibilities included mentoring, helping in preparation of presentations, and grading homework and projects"]
                },
                {
                    company: "Shahid Beheshti University",
                    title: "Teaching Assistant, Technical English Language",
                    location: "Tehran, Iran",
                    years: "Jan. 2019 - Jan. 2020",
                    theDescriptions: [
                        "Instructed by Dr. Mojtaba Vahidi-Asl",
                        "Responsibilities included mentoring, guest lecturing, and grading homework and projects"]
                },
                {
                    company: "Shahid Beheshti University",
                    title: "Teaching Assistant, Advanced Programming",
                    location: "Tehran, Iran",
                    years: "Jan. 2019 - Jun. 2019",
                    theDescriptions: [
                        "Instructed by Dr. Mojtaba Vahidi-Asl",
                        "Responsibilities included mentoring, and grading homework and projects"]
                },
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    goToNextPage() {
        if (this.state.currPage < this.state.workExperiences.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }

    valueGenerator() {
        const workExperience = this.state.workExperiences[this.state.currPage - 1];
        return (
            // this.state.educations.map(education => (
            //     <Education school={education.school} degree={education.degree} years={education.years} theDescription={education.theDescription} />
            // ))
            <WorkExperience company={workExperience.company} title={workExperience.title} location={workExperience.location} years={workExperience.years} theDescriptions={workExperience.theDescriptions} />
        );
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.workExperiences.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollRight"} id={"WorkExperiences"}>
                <div className={"ForgeContainer"} >
                    <div className={"Forge"} id={"Forge"}
                         onMouseEnter={() => this.toggleTextVisibility()}
                         onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Work Experiences
                            </span>
                        }
                    </div>
                    <div className={"ForgeShadow"} id={"ForgeShadow"} />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"PaperScrollRight"}>
                    <PaperScrollContent position={"Right"} value={this.valueGenerator()} nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.workExperiences.length}
                    />
                </div>
            </div>
        );
    }
}

class WorkExperience extends React.Component {
    render() {
        return (
            <div>
                <h1 className={"WorkExperienceHeader"}>
                    {this.props.title}
                </h1>
                at
                <h2 className={"WorkExperienceCompany"}>
                    {this.props.company}
                </h2>
                <div className={"WorkExperienceLocation"}>
                    {this.props.location}
                </div>
                <p className={"WorkExperienceYears"}>
                    {this.props.years}
                </p>
                <hr className={"WorkExperienceHR"} />
                <ul className={"WorkExperienceDescriptionList"}>
                    {this.props.theDescriptions.map(theDescription => (
                        <li className={"WorkExperienceDescriptionListItem"} key={theDescription}>
                            {theDescription}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}