import './Projects.css';
import '../App.css';

import TheLostAcornCityThumbnail from "./ProjectThumbnails/TheLostAcornCity.png";
import MasterYiHeadThumbNail from "./ProjectThumbnails/MasterYiHead.png";
import TwitterThumbnail from "./ProjectThumbnails/Twitter.png";
import StarWarsEncyclopediaThumbnail from "./ProjectThumbnails/StarWarsEncyclopedia.png";
import LuxoJRThumbnail from "./ProjectThumbnails/LuxoJR.png";
import LowPolyChessThumbnail from "./ProjectThumbnails/ChessBoard.png";
import NecklaceThumbnail from "./ProjectThumbnails/Necklace.png";
import TwoCarsThumbnail from "./ProjectThumbnails/TwoCars.png";
import Two048Thumbnail from "./ProjectThumbnails/2048.png";
import QuoridorThumbnail from "./ProjectThumbnails/Quoridor.png";
import MyWebsiteThumbnail from "./ProjectThumbnails/myWebsite.png";
import GardenofAIdenThumbnail from "./ProjectThumbnails/gardenofAIden.png";

import React from "react";
import PaperScrollContent from "../PaperScrollContent";

export default class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            textVisibility: false,
            currPage: 1,
            projects: [
                {
                    title: "Garden of AIden",
                    role: "Unity Developer",
                    date: "Jul. 2021",
                    projectURL: "https://github.com/gameaischool2021members/garden-of-aiden",
                    projectThumbnail: GardenofAIdenThumbnail,
                    theDescriptions: [
                        "As a 6-member-team, we implemented a real-time " +
                        "automated nature design tool for game environment designers.",

                        "Using a Generative Adversarial Network (GAN), " +
                        "the model learned the general design patterns of " +
                        "the environment (the vegetations), thereafter it " +
                        "was able to create real-time vegetation based on " +
                        "the terrain elevation.",

                        "This project was selected as the best project " +
                        "among all; 11 total submitted projects by all the " +
                        "jamming teams during \"The 3rd International " +
                        "Summer School on AI and Games Game " +
                        "Jam\"."
                    ]
                },
                {
                    title: "Personal Website, Kian Razavi Satvati's",
                    role: "Web Designer and Developer/Maintainer",
                    date: "Feb. 2021 - Present",
                    projectURL: "https://kiansatvati.ir",
                    projectThumbnail: MyWebsiteThumbnail,
                    theDescriptions: [
                        "The website that you are currently visiting. :)",

                        "My personal website which was built using the ReactJS."
                    ]
                },
                {
                    title: "The Lost Acorn City",
                    role: "Game Designer and Developer",
                    date: "Oct. 2020 - Feb. 2021",
                    projectURL: "https://globalgamejam.org/2021/games/lost-acorn-city-8",
                    projectThumbnail: TheLostAcornCityThumbnail,
                    theDescriptions: [
                        "A 2D adventure-strategy game was made in the " +
                        "Unity game engine.",

                        "This project has been archived in the Global " +
                        "Game Jam; The World’s Largest Game Creation " +
                        "Event, in 2021."
                    ]
                },
                {
                    title: "Game Character Head Sculpt",
                    role: "Digital Sculptor",
                    date: "Feb. 2021",
                    projectURL: "https://www.artstation.com/artwork/ykl563",
                    projectThumbnail: MasterYiHeadThumbNail,
                    theDescriptions: [
                        "This was a Blender sculpting practice.",

                        "I have used \"Master Yi\"'s head, a character from \"League of Legends\" game, " +
                        "as the reference",

                        "This project was a part of the GameDev course " +
                        "\"Complete Blender Creator Learn 3D Modelling for Beginners\"."
                    ]
                },
                {
                    title: "Twitter Clone",
                    role: "Frontend Developer",
                    date: "Jan. 2021",
                    projectURL: "https://github.com/XxNE0xX/TwitterFront",
                    projectThumbnail: TwitterThumbnail,
                    theDescriptions: [
                        "As a 3-member-team, we implemented both the " +
                        "server and the client sides of a cloned version of " +
                        "Twitter.",

                        "My assigned responsibility included the design of " +
                        "the User Interface (UI) as well as the usage of " +
                        "the provided Application Programming Interface " +
                        "(API) to communicate with the server.",

                        "This project was made using the ReactJS and Java " +
                        "Swift languages.",

                        "It was the final project of my \"Internet Engineering\" academic course."
                    ]
                },
                {
                    title: "Star Wars Ships Encyclopedia",
                    role: "Programmer",
                    date: "Dec. 2020",
                    projectURL: "https://kiansatvati.ir/StarWarsShipsEncyclopedia/",
                    projectThumbnail: StarWarsEncyclopediaThumbnail,
                    theDescriptions: [
                        "The project was made using Javascript.",

                        "It was a project of my \"Internet Engineering\" academic course " +
                        "for learning different aspects of combining JS with CSS and HTML."
                    ]
                },
                {
                    title: "Recreation of \"Luxo Jr.\" Animation (Pixar's logo intro)",
                    role: "3D Designer, Animator, and Sound Designer",
                    date: "Oct. 2020",
                    projectURL: "https://imwonda.artstation.com/projects/QrRz0d",
                    projectThumbnail: LuxoJRThumbnail,
                    theDescriptions: [
                        "I recreated the famous Pixar lamp, Luxo JR., " +
                        "displaying my own name instead of the word " +
                        "”PIXAR”",

                        "The modeling, shading, rigging, and animating " +
                        "was done on the Blender.",

                        "The sound effects were made in the Audacity.",

                        "This project was a part of the GameDev course " +
                        "\"Complete Blender Creator Learn 3D Modelling for Beginners\"."
                    ]
                },
                {
                    title: "Low-poly Chess Set 3D modeling",
                    role: "3D Designer",
                    date: "Sep. 2020",
                    projectURL: "https://imwonda.artstation.com/projects/XnGYNw",
                    projectThumbnail: LowPolyChessThumbnail,
                    theDescriptions: [
                        "This was a texturing and low-poly design practice.",

                        "This project was a part of the GameDev course " +
                        "\"Complete Blender Creator Learn 3D Modelling for Beginners\"."
                    ]
                },
                {
                    title: "Jewelry 3D modeling: \"The Karika Necklace\"",
                    role: "3D Designer",
                    date: "Jul. 2020",
                    projectURL: "https://imwonda.artstation.com/projects/nYZRXE",
                    projectThumbnail: NecklaceThumbnail,
                    theDescriptions: [
                        "It was the final project of my \"Computational Graphics\" academic course.",

                        "I 3D modeled a necklace using the product with the same name at Bluestone website as the reference",

                        "The modeling was done in Rhino 3D."
                    ]
                },
                {
                    title: "Recreation of \"2 Cars\" game",
                    role: "Programmer",
                    date: "Jul. 2020",
                    projectURL: "https://gitlab.com/XxNE0xX/twocars",
                    projectThumbnail: TwoCarsThumbnail,
                    theDescriptions: [
                        "It was a university project for \"Game Development\" academic supplementary course.",

                        "The game was made in the Unity game engine."
                    ]
                },
                {
                    title: "Recreation of \"2048\" game",
                    role: "Programmer",
                    date: "Jul. 2020",
                    projectURL: "https://gitlab.com/XxNE0xX/2048",
                    projectThumbnail: Two048Thumbnail,
                    theDescriptions: [
                        "It was a university project for \"Game Development\" academic supplementary course.",

                        "The game was made in the Unity game engine."
                    ]
                },
                {
                    title: "Digitalizing \"Quoridor\" game",
                    role: "Programmer",
                    date: "May. 2020",
                    projectURL: "https://gitlab.com/XxNE0xX/quoridor",
                    projectThumbnail: QuoridorThumbnail,
                    theDescriptions: [
                        "I tried digitalizing the table-top game \"Quoridor\" on the Unity game engine.",

                        "The game had local multiplayer feature."
                    ]
                },
                {
                    title: "Implementation of an email spam detector using neural networks",
                    role: "Programmer",
                    date: "Jan. 2020",
                    projectURL: "",
                    theDescriptions: [
                        "It was the final project of my \"Artificial Intelligence\" academic course.",

                        "The project was a \"supervised learning\" practice using \"gradient descent\" algorithm."
                    ]
                },
                {
                    title: "Implementation of \"8-puzzle\" solver AI agent",
                    role: "Programmer",
                    date: "Nov. 2019",
                    projectURL: "",
                    theDescriptions: [
                        "The project was a practice with different search algorithms for Artificial Intelligent " +
                        "agents, such as A*, BFS, DFS, and IDA*"
                    ]
                },
                {
                    title: "A multiplayer socket-based Chess",
                    role: "Programmer",
                    date: "Jun. 2018",
                    projectURL: "https://github.com/rferdosi/Chess",
                    theDescriptions: [
                        "The game was made using \"JavaFX\" library of Java programming language.",

                        "It was the final project of my \"Advanced Programming\" academic course."
                    ]
                },
                {
                    title: "Recreation of \"Hitman GO\" game (console based)",
                    role: "Programmer",
                    date: "Apr. 2018",
                    projectURL: "",
                    theDescriptions: [
                        "It was a university project for \"Advanced Programming\" academic supplementary course.",

                        "The project was made using Java programming language",

                        "It was an console-based game, for the purpose of practicing \"Object-Oriented-Programming\"."
                    ]
                },
                {
                    title: "Recreation of \"Doodle Jump\" game",
                    role: "Programmer",
                    date: "Jan. 2018",
                    projectURL: "",
                    theDescriptions: [
                        "It was the final project of my \"Introduction to the Programming\" academic course.",

                        "The project was made using SDL library of C++ programming language."
                    ]
                },
            ]
        }
    }

    toggleTextVisibility() {
        this.setState({textVisibility:!this.state.textVisibility});
    }

    goToNextPage() {
        if (this.state.currPage < this.state.projects.length){
            this.setState({currPage:this.state.currPage + 1});
        }
    }

    goToPrevPage() {
        if (this.state.currPage > 1){
            this.setState({currPage:this.state.currPage - 1});
        }
    }

    valueGenerator() {
        const project = this.state.projects[this.state.currPage - 1];
        return (
            <Project title={project.title} role={project.role} date={project.date}
                     projectURL={project.projectURL} projectThumbnail={project.projectThumbnail} theDescriptions={project.theDescriptions} />
        );
    }

    render() {
        let nextAvailable = this.state.currPage < this.state.projects.length;
        let prevAvailable = this.state.currPage > 1;
        return (
            <div className={"MainPanelScrollLeft"} id={"Projects"}>
                <div className={"PaperScrollLeft"}>
                    <PaperScrollContent position={"Left"} value={this.valueGenerator()} nextAvailable={nextAvailable} prevAvailable={prevAvailable}
                                        nextPage={() => this.goToNextPage()} prevPage={() => this.goToPrevPage()}
                                        currPage={this.state.currPage} totalPages={this.state.projects.length}
                    />
                </div>
                <div className={"CobblestoneRoad"} />
                <div className={"DragonContainer"} >
                    <div className={"Dragon"} id={"Dragon"} />
                    <div className={"DragonStars"} id={"DragonStars"}
                         onMouseEnter={() => this.toggleTextVisibility()}
                         onMouseLeave={() => this.toggleTextVisibility()}>
                        {this.state.textVisibility &&
                            <span>
                                Projects
                            </span>
                        }

                    </div>
                    <div className={"DragonShadow"} id={"DragonShadow"} />
                </div>
            </div>
        );
    }
}

class Project extends React.Component {
    render() {
        let URL = this.props.projectURL;
        let thumbnail = this.props.projectThumbnail;
        return (
            <div>
                <h1 className={"ProjectTitle"}>
                    {this.props.title}
                </h1>
                <h2 className={"ProjectRole"}>
                    {this.props.role}
                </h2>
                <div className={"ProjectBottomSideContainer"}>
                    {(URL !== "") && <a className={"ProjectURL"} href={URL} target={"_blank"}>See Project</a>}
                    <div className={"ProjectDate"}>
                        {this.props.date}
                    </div>
                </div>
                <hr className={"ProjectHR"} />
                {thumbnail && <img className={"ProjectThumbnail"} src={this.props.projectThumbnail}/>}
                <ul className={"ProjectDescriptionList"}>
                    {this.props.theDescriptions.map(theDescription => (
                        <li className={"ProjectDescriptionListItem"} key={theDescription}>
                            {theDescription}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}